.Dialog {

  &Container {
    align-items: flex-start !important;
  }
  
  &Body {
    vertical-align: top;
  }
}
