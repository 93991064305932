html { height: 100%; overflow:auto; }
body { height: 100%; }

.AlertSuccessContentFullWidth {
  & .MuiAlert-message {
    width: 100%;
  }
}

.ClickableText {
  cursor: pointer;
  color: #1976d2;
}

// .MethodInputMargin {
//   padding-top: 1vh;
// }

// .MethodInputLevel {
//   padding-left: 0.5vw;
// }

// .MuiTextField-root input {
//   /* 14.5px = 18.5px - 4px (note: 18.5px is the input's default padding top and bottom) */
//   padding-top: 14.5px;
//   padding-bottom: 14.5px; 
// }

// .MuiTextField-root label {
//   top: -4px;
// }

// .MuiTextField-root label[data-shrink='true'] {
//   top: 0;
// }

.Message {
  width: 100%;
}
